import React, { useState, useEffect, useMemo } from 'react';
import api from 'services/api';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formatter';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';

import Select from 'v3/components/Select';

import { useTravel } from 'v3/pages/Travel';
import { WhatsAppIcon } from './styles';
import DriverAttachments from '../DriverAttachments';

function Driver({ errors, setDriver, driver, editing, onUpdateDriver }) {

  const travel = useTravel();

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [driverAttachments, setDriverAttachments] = useState([]);

  function checkDriverPaymentMethod() {
    if (travel.data?.driverData) {
      setPaymentMethod(travel.data.driverData?.payment?.name);
    } else if (travel.data?.shipperProps) {
      setPaymentMethod(travel.data?.shipperProps?.payment?.name);
    }
  }

  useEffect(() => {
    checkDriverPaymentMethod();
  }, [travel.data]);

  useEffect(() => {
    async function fetchAttachments(driver_id) {
      const attachmentsDriver = await api.get(
        `driver/${driver_id}/attachments`
      );

      setDriverAttachments(attachmentsDriver);
    }

    if (travel.data.driverId) fetchAttachments(travel.data.driverId);
  }, [travel.data.driverId]);

  async function fetchDriver(search) {
    try {
      const response = await api.get('drivers/select', { params: { search } });
      return response.data;
    } catch (error) {
      return [];
    }
  }


  const driverPhone = useMemo(() => {
    const assertedPhone =
      travel.data.driver?.phoneCooperplace ||
      travel.data.driver?.user?.phonenumber;

    let whatsappPhone = assertedPhone?.replace(/\D/g, '');

    const PHONE_LENGTH_WITHOUT_DDI = 13;
    if (whatsappPhone?.length <= PHONE_LENGTH_WITHOUT_DDI) {
      whatsappPhone = '55'.concat(whatsappPhone);
    }

    return {
      raw: assertedPhone,
      whatsapp: whatsappPhone,
    };
  }, [travel.data.driver]);

  useEffect(() => {
    setDriver(travel?.data?.driver);
  }, [travel?.data?.driver?.id]);

  function onChangeDriver(value) {
    setDriver(value);
    onUpdateDriver(value);
    travel.setData(prev => ({
      ...prev,
      oldDriver: !!travel.data?.driver,
    }));
  }

  if (travel.isLoading) {
    return null;
  }

  return (
    <>
      {editing ? (
        <Row className="form">
          <Col xs={12}>
            <Select.Async
              onSearch={fetchDriver}
              value={driver}
              label="Motorista *"
              onChange={onChangeDriver}
              getOptionLabel={option =>
                option?.socialName ?? option?.social_name
              }
              getOptionValue={option => option?.id}
              error={errors}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="dark">
              Motorista
            </Text>

            <Link
              to={
                travel.data.driver?.socialName &&
                `/motoristas/${travel.data?.driver?.id}`
              }
              target="_blank"
            >
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel.data.driver?.socialName || 'Não informado'}
              </Text>
            </Link>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            {travel.data.driver?.isInternational ? 'Documento' : 'CPF'}
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data.driver?.cpfCnpj || 'Não informado'}
          </Text>
        </Col>

        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Telefone
          </Text>
          <a
            href={`https://api.whatsapp.com/send?l=pt-BR&phone=${driverPhone.whatsapp}`}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <WhatsAppIcon size={18} />
              <Text type="regular" color="gray">
                {driverPhone.raw || 'Não informado'}
              </Text>
            </div>
          </a>
        </Col>

        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            CNH
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data.driver?.cnhNumber || 'Não informado'}
          </Text>
        </Col>

        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Data de expiração
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {formatDate(travel.data.driver?.cnhExpDate) || 'Não informado'}
          </Text>
        </Col>
        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Validade cadastro
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.personVerifications
              ? formatDate(travel.data?.personVerifications?.dueDate)
              : 'Não informado'}
          </Text>
        </Col>
        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Nível de serviço
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data?.driverData?.descriptionServiceLevel ||
              'Não informado'}
          </Text>
        </Col>
        {travel.data.personVerifications?.dueDate && (
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Validado por
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel.data?.personVerifications?.verifiedByUser?.username ||
                'Não informado'}
            </Text>
          </Col>
        )}
        <Col xs={12} md={6}>
          <Text type="label" color="dark">
            Tags
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data.driverData?.tags?.map(tag => tag.name)?.join(', ') ||
              'Não informado'}
          </Text>
        </Col>
        <Col xs={12} md={12}>
          <Text type="label" color="dark">
            Método de pagamento
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {paymentMethod || 'Não informado'}
          </Text>
        </Col>
        <Col md={12}>
          <DriverAttachments
            driverAttachments={driverAttachments || { data: {} }}
          />
        </Col>
      </Row>
    </>
  );
}

export default Driver;
