import React, { useEffect, useMemo, useState } from 'react';
import {
  MonitoringEquipmentContainer,
  MonitoringEquipmentCount,
  MonitoringEquipmentToggle,
  StyledCarousel,
  StyledCarouselItem,
  StyledTrackedContainer,
} from 'v3/pages/Policy/styles';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { Input, Select, Text } from 'v3/components';

import CheckBox from 'components/CheckBox';
import { FaArrowLeft, FaArrowRight, FaTrash } from 'react-icons/fa';
import { fetchSelectOptions } from 'utils/fetches';
import { sanitize } from 'dompurify';
import { Col } from 'react-bootstrap';
import { InsertButton } from './InsertButton';
import { usePolicyRegister } from '../../context';
import { SectionDialog } from '../Dialogs/SectionDialog';
import { fetchTrackerTypes } from '../../utils';

export function MonitoringEquipamentsSection({
  opIndex,
  productIndex,
  vehicleIdx,
  rangeIdx,
  range,
}) {
  const {
    filterPolicyInUse,
    handleAddNewMonitoringEquipamentGroup,
    handleAddRangeItem,
    handleAddRangeMonitoringEquipament,
    windowWidth,
    errors,
    handleRemoveGroupOption,
    trackerModelOptions,
    setTrackerModelOptions,
    setCurrentOpIndex,
    setCurrentPdIndex,
    setCurrentVehicleIndex,
    setCurrentRangeIndex,
    setCurrentMonitoringEquipamentIndex,
    setDialogPolicyInUse,
    openGroupModal,
    setOpenGroupModal,
    index,
    handleSelect,
    trackerOptions,
    currentOpIndex,
    currentPdIndex,
    currentVehicleIndex,
    currentRangeIndex,
    currentMonitoringEquipamentIndex,
  } = usePolicyRegister();

  function handleSearchTracker(search) {
    if (!search) {
      return trackerOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return trackerOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }

  function handleRemoveMonitoringEquipamentGroup(
    opIndex,
    productIndex,
    vehicleIdx,
    rangeIdx,
    groupIdx
  ) {
    const group = range?.monitoringEquipaments[groupIdx];
    const groupsInUse = filterPolicyInUse(
      'policy_group_equipament_id',
      group?.id
    );
    if (groupsInUse.length > 0) {
      setDialogPolicyInUse({
        open: true,
        label: 'equipamento de monitoramento',
        loads: groupsInUse,
      });
      return;
    }

    setCurrentOpIndex(opIndex);
    setCurrentPdIndex(productIndex);
    setCurrentVehicleIndex(vehicleIdx);
    setCurrentRangeIndex(rangeIdx);
    setCurrentMonitoringEquipamentIndex(groupIdx);
    setOpenGroupModal(true);
  }

  const [locatorOptions, setLocatorOptions] = useState([]);
  const [immobilizersOptions, setImmobilizersOptions] = useState([]);
  const [baitOptions, setBaitOptions] = useState([]);

  const disabledBaitInput = useMemo(() => {
    const loadsInUse = filterPolicyInUse('policy_vehicle_id', rangeIdx + 1);
    return !!loadsInUse.length;
  }, [range]);

  async function searchLocalizators(search) {
    setLocatorOptions([]);
    const data = await fetchSelectOptions(
      'select/trackers',
      {
        search: search ? sanitize(search) : '',
        is_locator: true,
        status: true,
      }
    );

    setLocatorOptions(data);
    return data;
  }

  async function searchImmobilizers(search) {
    setImmobilizersOptions([]);
    const data = await fetchSelectOptions(
      'select/trackers',
      {
        search: sanitize(search),
        is_immobilizer: true,
        status: true,
      }
    );
    setImmobilizersOptions(data);
    return data;
  }

  async function searchBaits(search) {
    setBaitOptions([]);
    const data = await fetchSelectOptions(
      'select/trackers',
      {
        search: sanitize(search),
        is_bait: true,
        status: true,
      }
    );
    setBaitOptions(data);
    return data;
  }

  useEffect(() => {
    searchLocalizators();
    searchImmobilizers();
    searchBaits();
  }, [])

  return (
    <>
      <SectionDialog
        label="Grupo de monitoramento"
        open={openGroupModal}
        confirAction={() =>
          handleRemoveGroupOption(
            currentOpIndex,
            currentPdIndex,
            currentVehicleIndex,
            currentRangeIndex,
            currentMonitoringEquipamentIndex
          )
        }
        handleClose={() => setOpenGroupModal(false)}
      />
      <MonitoringEquipmentToggle
        showNewGroupButton={range?.showMonitoringEquipaments}
      >
        <button
          type="button"
          onClick={() =>
            handleAddRangeItem(
              opIndex,
              productIndex,
              vehicleIdx,
              rangeIdx,
              'showMonitoringEquipaments',
              !range?.showMonitoringEquipaments
            )
          }
        >
          {range.showMonitoringEquipaments ? (
            <AiOutlineArrowUp />
          ) : (
            <AiOutlineArrowDown />
          )}
        </button>

        <Text type="body" color="dark" weight={500}>
          Equipamentos de Monitoramento
          {range.showMonitoringEquipaments && (
            <InsertButton
              onClick={() =>
                handleAddNewMonitoringEquipamentGroup(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx
                )
              }
              title="Clique para inserir um grupo"
              label="Inserir grupo"
            />
          )}
        </Text>
      </MonitoringEquipmentToggle>
      {range?.showMonitoringEquipaments ? (
        <MonitoringEquipmentContainer>
          <MonitoringEquipmentCount>{index + 1}</MonitoringEquipmentCount>
          <div>
            <div
              style={{
                paddingLeft: '1.5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text as="span" color="dark" type="label" weight={500}>
                  Rastreado *
                </Text>
                {errors[
                  `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].tracked`
                ] && (
                  <Text color="danger" type="label">
                    {
                      errors[
                        `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].tracked`
                      ]
                    }
                  </Text>
                )}
              </div>
              <StyledTrackedContainer>
                <CheckBox
                  checked={range?.tracked === true}
                  onChange={() =>
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'tracked',
                      range?.tracked ? null : true
                    )
                  }
                  label="Sim"
                />

                <CheckBox
                  checked={range?.tracked === false}
                  onChange={() =>
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'tracked',
                      range?.tracked === false ? null : false
                    )
                  }
                  label="Não"
                />
              </StyledTrackedContainer>
            </div>
            {range?.monitoringEquipaments?.length !== 0 ? (
              <StyledCarousel
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
                prevIcon={
                  index > 0 ? (
                    <button
                      type="button"
                      disabled={range?.monitoringEquipaments?.length === 1}
                      title="Grupo anterior"
                    >
                      <FaArrowLeft />
                    </button>
                  ) : (
                    <></>
                  )
                }
                nextIcon={
                  index < range?.monitoringEquipaments?.length - 1 ? (
                    <button
                      type="button"
                      disabled={range?.monitoringEquipaments?.length === 1}
                      title="Próximo grupo"
                    >
                      <FaArrowRight />
                    </button>
                  ) : (
                    <></>
                  )
                }
                indicators={false}
                slide={false}
              >
                {range?.monitoringEquipaments?.map((group, groupIdx) => {
                  return (
                    <StyledCarouselItem>
                      <div>
                        <header>
                          <Text
                            type="regular"
                            weight={500}
                            color="dark"
                            as="span"
                          >
                            {group?.name?.length > 0
                              ? group?.name
                              : `Grupo atual  ${groupIdx + 1}`}
                          </Text>
                          <button
                            type="button"
                            onClick={() =>
                              handleRemoveMonitoringEquipamentGroup(
                                opIndex,
                                productIndex,
                                vehicleIdx,
                                rangeIdx,
                                groupIdx
                              )
                            }
                          >
                            <FaTrash />
                            <Text>remover grupo</Text>
                          </button>
                        </header>
                        <div className="groupContent">
                          <Input
                            label="Nome do grupo"
                            value={group?.name}
                            onChange={event => {
                              if (event?.target?.value?.length > 50) {
                                return;
                              }
                              handleAddRangeMonitoringEquipament(
                                opIndex,
                                productIndex,
                                vehicleIdx,
                                rangeIdx,
                                groupIdx,
                                'name',
                                event.target.value
                              );
                            }}
                            error={
                              errors[
                                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].monitoringEquipaments[${groupIdx}].name`
                              ]
                            }
                          />
                          <Select.Async
                            closeMenuOnSelect={false}
                            labelColor="dark"
                            label="Tecnologia(marca rastreador)"
                            value={group?.tecnologies}
                            placeholder="Selecione"
                            multiple
                            horizontal={windowWidth <= 992}
                            modalHeading="Tecnologias"
                            modalBodyTitle="Informe uma tecnologia"
                            options={trackerOptions}
                            enableSelectAll
                            onChange={value =>
                              handleAddRangeMonitoringEquipament(
                                opIndex,
                                productIndex,
                                vehicleIdx,
                                rangeIdx,
                                groupIdx,
                                'tecnologies',
                                value
                              )
                            }
                            getOptionLabel={option =>
                              option.name ?? option?.label
                            }
                            getOptionValue={option => option.id}
                            selectValuesContainerMaxHeight="200px"
                            selectValuesContainerOverflowY="scroll"
                            onSearch={search => handleSearchTracker(search)}
                            error={
                              errors[
                                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].monitoringEquipaments[${groupIdx}].tecnologies`
                              ]
                            }
                          />
                          <Select.Async
                            closeMenuOnSelect={false}
                            labelColor="dark"
                            tooltip="Depende da tecnologia para ser habilitado"
                            label="Modelo de Rastreador"
                            value={group?.equipments}
                            placeholder="Busque um modelo"
                            multiple
                            horizontal={windowWidth <= 992}
                            modalHeading="Modelos"
                            modalBodyTitle="Informe um modelo"
                            enableSelectAll
                            onChange={value =>
                              handleAddRangeMonitoringEquipament(
                                opIndex,
                                productIndex,
                                vehicleIdx,
                                rangeIdx,
                                groupIdx,
                                'equipments',
                                value
                              )
                            }
                            getOptionLabel={option =>
                              option.name ?? option?.label
                            }
                            getOptionValue={option => option.id}
                            selectValuesContainerMaxHeight="200px"
                            selectValuesContainerOverflowY="scroll"
                            options={trackerModelOptions}
                            onSearch={async search => {
                              setTrackerModelOptions([]);
                              const trackerIds = group?.tecnologies?.map(
                                item => item.id
                              );
                              const data = await fetchTrackerTypes(
                                trackerIds,
                                search
                              );
                              setTrackerModelOptions(data);
                              return data;
                            }}
                            isDisabled={
                              !group?.tecnologies ||
                              group?.tecnologies?.length <= 0
                            }
                            error={
                              errors[
                                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].monitoringEquipaments[${groupIdx}].equipments`
                              ]
                            }
                          />

                          <Select.Async
                            closeMenuOnSelect={false}
                            labelColor="dark"
                            label="Localizador"
                            value={group?.locators}
                            placeholder="Busque um localizador"
                            multiple
                            horizontal={windowWidth <= 992}
                            modalHeading="Localizadores"
                            modalBodyTitle="Busque um localizador"
                            options={locatorOptions}
                            onSearch={searchLocalizators}
                            removeInputMin
                            onInputChange={(value) => !value.length && searchLocalizators(value)}
                            onChange={value =>
                              handleAddRangeMonitoringEquipament(
                                opIndex,
                                productIndex,
                                vehicleIdx,
                                rangeIdx,
                                groupIdx,
                                'locators',
                                value
                              )
                            }
                            getOptionLabel={option =>
                              option.name ?? option?.label
                            }
                            enableSelectAll
                            getOptionValue={option => option.id}
                            selectValuesContainerMaxHeight="200px"
                            selectValuesContainerOverflowY="scroll"
                          />

                          <Select.Async
                            closeMenuOnSelect={false}
                            labelColor="dark"
                            label="Imobilizador"
                            value={group?.immobilizers}
                            placeholder="Busque um imobilizador"
                            multiple
                            horizontal={windowWidth <= 992}
                            modalHeading="Imobilizadores"
                            modalBodyTitle="Busque um imobilizador"
                            options={immobilizersOptions}
                            enableSelectAll
                            onSearch={searchImmobilizers}
                            removeInputMin
                            onInputChange={(value) => !value.length && searchImmobilizers(value)}
                            onChange={value =>
                              handleAddRangeMonitoringEquipament(
                                opIndex,
                                productIndex,
                                vehicleIdx,
                                rangeIdx,
                                groupIdx,
                                'immobilizers',
                                value
                              )
                            }
                            getOptionLabel={option =>
                              option.name ?? option?.label
                            }
                            getOptionValue={option => option.id}
                            selectValuesContainerMaxHeight="200px"
                            selectValuesContainerOverflowY="scroll"
                          />

                          <div>
                            <Input
                              label="Quantidade de iscas necessárias"
                              tooltip={
                                disabledBaitInput
                                  ? 'Existem cargas vinculadas a essa apólice'
                                  : 'Número entre 0 e 4'
                              }
                              type="number"
                              value={group?.bait_quantity}
                              onKeyDown={evt =>
                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              disabled={disabledBaitInput}
                              onChange={({ target }) => {
                                if (
                                  Number(target?.value) > 4 ||
                                  Number(target?.value < 0)
                                ) {
                                  return;
                                }

                                handleAddRangeMonitoringEquipament(
                                  opIndex,
                                  productIndex,
                                  vehicleIdx,
                                  rangeIdx,
                                  groupIdx,
                                  'bait_quantity',
                                  target?.value
                                );
                              }}
                            />
                          </div>

                          <div>
                            <Select.Async
                              closeMenuOnSelect={false}
                              labelColor="dark"
                              label="Modelos de iscas disponíveis"
                              value={group?.baits}
                              placeholder="Busque uma isca"
                              multiple
                              horizontal={windowWidth <= 992}
                              modalHeading="Iscas"
                              modalBodyTitle="Busque uma isca"
                              options={baitOptions}
                              removeInputMin
                              onInputChange={(value) => !value.length && searchBaits(value)}
                              onSearch={searchBaits}
                              onChange={value =>
                                handleAddRangeMonitoringEquipament(
                                  opIndex,
                                  productIndex,
                                  vehicleIdx,
                                  rangeIdx,
                                  groupIdx,
                                  'baits',
                                  value
                                )
                              }
                              getOptionLabel={option =>
                                option.name ?? option?.label
                              }
                              getOptionValue={option => option.id}
                              selectValuesContainerMaxHeight="200px"
                              selectValuesContainerOverflowY="scroll"
                              enableSelectAll
                              error={
                                errors[
                                  `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].monitoringEquipaments[${groupIdx}].baits`
                                ]
                              }
                            />
                          </div>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Text
                                as="span"
                                color="dark"
                                type="label"
                                weight={500}
                              >
                                Escolta *
                              </Text>
                              {errors[
                                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].monitoringEquipaments[${groupIdx}].escorted`
                              ] && (
                                <Text color="danger" type="label">
                                  {
                                    errors[
                                      `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].monitoringEquipaments[${groupIdx}].escorted`
                                    ]
                                  }
                                </Text>
                              )}
                            </div>
                            <div>
                              <CheckBox
                                checked={group.escorted === true}
                                onChange={() =>
                                  handleAddRangeMonitoringEquipament(
                                    opIndex,
                                    productIndex,
                                    vehicleIdx,
                                    rangeIdx,
                                    groupIdx,
                                    'escorted',
                                    group?.escorted ? null : true
                                  )
                                }
                                label="Sim"
                              />
                            </div>

                            <div>
                              <CheckBox
                                checked={group?.escorted === false}
                                onChange={() =>
                                  handleAddRangeMonitoringEquipament(
                                    opIndex,
                                    productIndex,
                                    vehicleIdx,
                                    rangeIdx,
                                    groupIdx,
                                    'escorted',
                                    group?.escorted === false ? null : false
                                  )
                                }
                                label="Não"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </StyledCarouselItem>
                  );
                })}
              </StyledCarousel>
            ) : errors[
                `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].monitoringEquipaments`
              ] ? (
              <Col
                xs={12}
                className="pt-5 d-flex justify-content-center align-items-center"
              >
                <Text weight={500} color="danger">
                  Informe no mínimo um grupo de monitoramento!
                </Text>
              </Col>
            ) : null}
          </div>
        </MonitoringEquipmentContainer>
      ) : null}
    </>
  );
}
