import React, { useEffect, useRef, useState } from 'react';
import { Button, Link, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import { Col, Divider, Row } from 'antd';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Pagination from 'v3/components/DataTable/Pagination';
import { Link as OriginalLink } from 'react-router-dom';
import { useModal } from 'hooks';
import { useFleetManagement } from '../../shared';
import RefuseLoadModal from './RefuseLoadModal';
import { DestinationContainer } from './styles';
import moment from 'moment';

function formatDestionationTime(startSchedule, scheduledTime) {
  const start = moment(startSchedule).format('DD/MM/YYYY HH:mm');
  const end = moment(scheduledTime).format('DD/MM/YYYY HH:mm');
  return `${start} até ${end}`;
}

function CargSuggestions() {
  const { selected, suggestionData, setSuggestionData, handleUpdateUnusedSuggested } = useFleetManagement();
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    perPage: 5,
    total: 0,
    lastPage: 0,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [registerSelected, setRegisterSelected] = useState(null);
  const snackbar = useSnackbar();
  const paginationRef = useRef(null);
  const refuseLoadModal = useModal();

  const fetchData = async (select, page = 1) => {
    setIsFetching(true);
    try {
      handleUpdateUnusedSuggested(select);
      const {
        data: { data: loadSuggestions, meta },
      } = await cooperplaceApi.get(
        `fleet/management/loadSugestion/${select.id}`,
        {
          params: { ...fetchOptions, page },
        }
      );
      setFetchOptions({
        page: meta.current_page,
        perPage: meta.per_page,
        total: meta.total,
        lastPage: meta.last_page,
      });
      setSuggestionData({ data: loadSuggestions, meta });
    } catch (error) {
      setSuggestionData({ data: [] });
      snackbar.show(
        <Text>
          {error?.response?.data?.error || 'Erro ao trazer sugestões de cargas'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setIsFetching(false);
    }
  };

  const onPageChanged = async page => {
    await fetchData(selected, page);
  };

  const handleRefuseLoad = regSelected => {
    setRegisterSelected(regSelected);
    refuseLoadModal.open();
  };

  useEffect(() => {
    if (selected?.id) {
      fetchData(selected);
    }
  }, [selected]);

  return (
    <>
      <RefuseLoadModal
        selected={registerSelected}
        setSelected={setRegisterSelected}
        modalHook={refuseLoadModal}
        vehicleManagementId={selected?.id}
        refetch={() => fetchData(selected)}
      />
      <div style={{ overflowX: 'auto' }}>
        <Row style={{ minWidth: 1000 }}>
          <Col span={2} className="p-1">
            <Text type="label" weight={500} color="dark">
              Carga
            </Text>
          </Col>
          <Col span={4} className="p-1">
            <Text type="label" weight={500} color="dark">
              Cliente
            </Text>
          </Col>
          <Col span={4} className="p-1">
            <Text type="label" weight={500} color="dark">
              Origem
            </Text>
          </Col>
          <Col span={4} className="p-1">
            <Text type="label" weight={500} color="dark">
              Destinos
            </Text>
          </Col>
          <Col span={4} className="p-1">
            <Text type="label" weight={500} color="dark">
              Produto
            </Text>
          </Col>
          <Col span={4} className="p-1">
            <Text type="label" weight={500} color="dark">
              Base de frete(Tomador)
            </Text>
          </Col>
          <Col span={2} className="p-1">
            <Text type="label" weight={500} color="dark">
              Ação
            </Text>
          </Col>
        </Row>
        {isFetching ? (
          <DefaultLoadingBodyComponent />
        ) : suggestionData.data?.length > 0 ? (
          <>
            <Row className="mt-2" align="middle" style={{ minWidth: 1000 }}>
              {suggestionData.data.map(
                ({
                  id,
                  taker,
                  loadOrigins,
                  loadDestinations,
                  product,
                  takerValue,
                  preLoad,
                }) => (
                  <React.Fragment key={id}>
                    <Col span={2} className="p-1">
                      <Link to={`/cargas/${id}`}>{id}</Link>
                    </Col>
                    <Col span={4} className="p-1">
                      <Text type="label" weight={500} color="dark">
                        {taker?.label}
                      </Text>
                    </Col>
                    <Col span={4} className="p-1">
                      <Text type="label" weight={500} color="dark">
                        {loadOrigins[0].formattedAddress}
                        <Text type="label" weight={500} color="dark" className="d-block mt-1">
                          <b>{formatDestionationTime(loadOrigins[0].startSchedule, loadOrigins[0].scheduledTime)}</b>
                        </Text>
                      </Text>
                    </Col>
                    <Col span={4} className="p-1">
                      <DestinationContainer>
                        {loadDestinations.map(
                          (
                            { formattedAddress, scheduledTime, startSchedule, id: destinationId },
                            index,
                            arr
                          ) => (
                            <React.Fragment key={destinationId}>
                              <Text type="label" weight={500} color="dark">
                                {formattedAddress}
                              </Text>
                              <Text type="label" weight={500} color="dark" className="d-block mt-1">
                                <b>{formatDestionationTime(startSchedule, scheduledTime)}</b>
                              </Text>
                              {arr.length - 1 !== index && <Divider />}
                            </React.Fragment>
                          )
                        )}
                      </DestinationContainer>
                    </Col>
                    <Col span={4} className="p-1">
                      <Text type="label" weight={500} color="dark">
                        {`${product?.name || 'Sem nome'} - ${product?.ncm || 'Sem NCM'
                          }`}
                      </Text>
                    </Col>
                    <Col span={4} className="p-1">
                      <Text type="label" weight={500} color="dark">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(takerValue)}
                      </Text>
                    </Col>
                    <Col span={2} className="p-1">
                      <div
                        style={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}
                      >
                        <OriginalLink
                          style={{ width: '100%' }}
                          to={`/${preLoad ? 'pre-cargas' : 'cargas'}/${id}`}
                          target="_blank"
                        >
                          <Button
                            style={{ padding: '4px 8px', width: '100%' }}
                            variant="success"
                          >
                            Atender
                          </Button>
                        </OriginalLink>
                        <Button
                          variant="error"
                          style={{ padding: '4px 8px', width: '100%' }}
                          onClick={() => handleRefuseLoad(id)}
                        >
                          Recusar
                        </Button>
                      </div>
                    </Col>
                    <Divider />
                  </React.Fragment>
                )
              )}
            </Row>
            <div className="d-flex align-items-center justify-content-between">
              <Text type="label" color="gray">
                Exibindo {suggestionData.data?.length} de {fetchOptions.total}{' '}
                registros
              </Text>
              <div>
                <Pagination
                  currentPage={fetchOptions.page}
                  pages={fetchOptions.lastPage}
                  onPageChanged={onPageChanged}
                  ref={paginationRef}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="mt-2 p-1">
            <Text type="label" weight={500} color="dark">
              Sem sugestões de cargas
            </Text>
          </div>
        )}
      </div>
    </>
  );
}

export default CargSuggestions;
