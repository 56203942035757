import { Col, Divider, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Text } from 'v3/components';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Pagination from 'v3/components/DataTable/Pagination';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import { useFleetManagement } from '../../shared';
import { DestinationContainer, ReasonLabel } from './styles';

function UnusedSuggested() {
  const { selected, unusedSuggestion, setUnusedSuggestion } =
    useFleetManagement();
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    perPage: 5,
    total: 0,
    lastPage: 0,
  });
  const [isFetching, setIsFetching] = useState(false);
  const snackbar = useSnackbar();
  const paginationRef = useRef(null);

  const fetchData = async (select, page = 1) => {
    setIsFetching(true);
    try {
      const {
        data: { data: loadSuggestions, meta },
      } = await cooperplaceApi.get(
        `fleet/management/rejectedLoad/${select.id}`,
        {
          params: { ...fetchOptions, page },
        }
      );
      setFetchOptions({
        page: meta.current_page,
        perPage: meta.per_page,
        total: meta.total,
        lastPage: meta.last_page,
      });
      setUnusedSuggestion({ data: loadSuggestions, meta });
    } catch (error) {
      setUnusedSuggestion({ data: [] });
      snackbar.show(
        <Text>
          {error?.response?.data?.error ||
            'Erro ao trazer sugestões de cargas não utilizadas'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setIsFetching(false);
    }
  };

  const onPageChanged = async page => {
    await fetchData(selected, page);
  };

  useEffect(() => {
    if (selected?.id) {
      fetchData(selected);
    }
  }, [selected]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Row style={{ minWidth: 1000 }}>
        <Col span={2} className="p-1">
          <Text type="label" weight={500} color="dark">
            Carga
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text type="label" weight={500} color="dark">
            Cliente
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text type="label" weight={500} color="dark">
            Origem
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text type="label" weight={500} color="dark">
            Destinos
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text type="label" weight={500} color="dark">
            Produto
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text type="label" weight={500} color="dark">
            Base de frete(Tomador)
          </Text>
        </Col>
        <Col span={2} className="p-1">
          <Text type="label" weight={500} color="dark">
            Motivo
          </Text>
        </Col>
      </Row>
      {isFetching ? (
        <DefaultLoadingBodyComponent />
      ) : unusedSuggestion.data?.length > 0 ? (
        <>
          <Row className="mt-2" align="middle" style={{ minWidth: 1000 }}>
            {unusedSuggestion.data.map(
              ({
                id,
                taker,
                loadOrigins,
                loadDestinations,
                product,
                takerValue,
                loadRejected,
              }) => (
                <React.Fragment key={id}>
                  <Col span={2} className="p-1">
                    <Link to={`/cargas/${id}`}>{id}</Link>
                  </Col>
                  <Col span={4} className="p-1">
                    <Text type="label" weight={500} color="dark">
                      {taker?.label}
                    </Text>
                  </Col>
                  <Col span={4} className="p-1">
                    <Text type="label" weight={500} color="dark">
                      {loadOrigins[0].formattedAddress}
                    </Text>
                  </Col>
                  <Col span={4} className="p-1">
                    <DestinationContainer>
                      {loadDestinations.map(
                        (
                          { formattedAddress, id: destinationId },
                          index,
                          arr
                        ) => (
                          <React.Fragment key={destinationId}>
                            <Text type="label" weight={500} color="dark">
                              {formattedAddress}
                            </Text>
                            {arr.length - 1 !== index && <Divider />}
                          </React.Fragment>
                        )
                      )}
                    </DestinationContainer>
                  </Col>
                  <Col span={4} className="p-1">
                    <Text type="label" weight={500} color="dark">
                      {`${product?.name || 'Sem nome'} - ${product?.ncm || 'Sem NCM'
                        }`}
                    </Text>
                  </Col>
                  <Col span={4} className="p-1">
                    <Text type="label" weight={500} color="dark">
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(takerValue)}
                    </Text>
                  </Col>
                  <Col
                    span={2}
                    className="p-1"
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <ReasonLabel $loadRejected={loadRejected}>
                      <Text type="label" weight={500} color="dark">
                        {loadRejected ? 'Recusado' : 'Vencida'}
                      </Text>
                    </ReasonLabel>
                  </Col>
                  <Divider />
                </React.Fragment>
              )
            )}
          </Row>
          <div className="d-flex align-items-center justify-content-between">
            <Text type="label" color="gray">
              Exibindo {unusedSuggestion.data?.length} de {fetchOptions.total}{' '}
              registros
            </Text>
            <div>
              <Pagination
                currentPage={fetchOptions.page}
                pages={fetchOptions.lastPage}
                onPageChanged={onPageChanged}
                ref={paginationRef}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="mt-2 p-1">
          <Text type="label" weight={500} color="dark">
            Sem sugestões de cargas
          </Text>
        </div>
      )}
    </div>
  );
}

export default UnusedSuggested;
