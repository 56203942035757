import React, { useState } from 'react';
import { Button, Modal, Text } from 'v3/components';
import moment from 'moment';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { useFleetManagement } from '../../shared';
import { BodyContainer, FooterContainer } from './styles';

function DeleteRegisterModal() {
  const {
    deleteModal: modalHook,
    selected,
    handleGetData,
  } = useFleetManagement();
  const [isFetching, setIsFetching] = useState(false);
  const snackbar = useSnackbar();

  const handleCancel = () => {
    modalHook.close();
  };

  const handleConfirm = async () => {

    // Aqui vai a requisição para deletar o registro
    try {
      setIsFetching(true);
      await cooperplaceApi.put('fleet/management/vehicleManagement', {
        data: [
          {
            vehicleManagementId: selected?.id,
            deletedAt: moment().toISOString(),
          },
        ],
      });

      await handleGetData();

      modalHook.close();
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.error
            ? error.response.data.error
            : 'Ocorreu um erro ao tentar deletar o registro'}
        </Text>
      );
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal
      show={modalHook.isOpen}
      heading="Deletar registro"
      body={
        <BodyContainer>
          <Text>{`Tem certeza que deseja excluir o registro da placa ${selected?.plate?.plate}?`}</Text>
        </BodyContainer>
      }
      footer={
        <FooterContainer>
          <Button loading={isFetching} onClick={handleCancel}>
            Cancelar
          </Button>
          <Button loading={isFetching} onClick={handleConfirm}>
            Excluir
          </Button>
        </FooterContainer>
      }
      handleClose={handleCancel}
    />
  );
}

export default DeleteRegisterModal;
