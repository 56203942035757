import moment from 'moment';
import { createContext } from 'utils/react-helpers';

const [FleetManagementProvider, useFleetManagement] = createContext(
  'FleetManagementContext'
);

export const DEFAULT_COLUMNS = ['PLATE', 'STATUS_OPERATIONAL'];

export const filtersMapper = (originalFilters, rowsPerPage) => {

  const mappedFilters = {
    startDate: moment(originalFilters.startDate, 'DD/MM/YYYY').isValid()
      ? moment(originalFilters.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : moment().subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: moment(originalFilters.endDate).isValid()
      ? moment(originalFilters.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    statusOperator: originalFilters.statusOperator.map(({ id }) => id),
    operation: originalFilters.tags.map(({ id }) => id),
    wagonPlate: originalFilters.plates
      .filter(({ vehicleType }) => vehicleType === 'implement')
      .map(({ id }) => id),
    vehiclePlate: originalFilters.plates
      .filter(({ vehicleType }) => vehicleType === 'vehicle')
      .map(({ id }) => id),
    wagonType: originalFilters.implementTypes.map(({ id }) => id),
    vehicleType: originalFilters.vehicleTypes.map(({ id }) => id),
    bodyType: originalFilters.bodyTypes.map(({ id }) => id),
    startAvailabilityForecast:
      originalFilters.startAvailabilityForecast &&
      moment(originalFilters.startAvailabilityForecast, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      ),
    endAvailabilityForecast:
      originalFilters.endAvailabilityForecast &&
      moment(originalFilters.endAvailabilityForecast, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      ),
    operator: originalFilters.operators.map(({ id }) => id),
    driver: originalFilters.drivers.map(({ id }) => id),
    aggregation: originalFilters.aggregation?.value,
    branchOfficeOrigin: originalFilters.branchOffices.map(({ id }) => id),
    travelStatus: originalFilters.travelStatus.map(({ id }) => id),
    withOccurrences: originalFilters.withOccurrences,
    active: originalFilters.active,
    page: originalFilters?.page || rowsPerPage?.page || 1,
    perPage: originalFilters?.perPage || rowsPerPage?.rowsPerPage || 10,
    fleet: originalFilters.fleet.map(({ id }) => id),
    emptyTravel: undefined,
  };

  return mappedFilters;
};

export { FleetManagementProvider, useFleetManagement };
