/* eslint-disable import/no-unresolved */
import api, { cooperplaceApi } from 'services/api';
import apiNotification from 'services/apis/notification';
import cargonApi from 'services/apis/cargon';
import { createContext } from 'utils/react-helpers';
import { convertNumberToDatabase } from 'v3/utils/formatter';
import moment from 'moment';
import loadSchema from './validator';

export async function fetchSelectOptions(url, params = {}) {
  try {
    const { data } = await api.get(url, { params });

    return data;
  } catch (err) {
    return [];
  }
}

export async function fetchCountries(setCountries) {
  try {
    const { data } = await api.get('countries');
    setCountries(data);
  } catch (err) {
    setCountries([]);
  }
}

export const freightTypes = [
  { label: 'Por viagem', value: 'CARGA_FECHADA' },
  { label: 'Por tonelada', value: 'POR_TONELADA' },
];

export const LoadContext = createContext({
  name: 'Add Load',
  strict: false,
});

async function validate(data, setErrors) {
  const errorList = {};
  try {
    await loadSchema.validate(data, {
      abortEarly: false,
      context: {
        isPreloadWithGRO: data?.isPreloadWithGRO,
        limitSchedule: data?.costCenter?.limit_schedule
      },
    });
    setErrors({ ...errorList });
    return true;
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error.message;
      });
      setErrors(errorList);
      return false;
    }
  }
}

export async function checkClientCreditLimit(clientId, freight, setErrors) {
  try {
    const response = await api.get(
      `v3/client/${clientId}/validateCreditLimit?freightValue=${+freight}`
    );
    if (!response.data.hasLimit) {
      setErrors(prev => ({ ...prev, client: 'Cliente sem limite de crédito' }));
    }
    return response.data?.hasLimit;
  } catch (error) {
    return false;
  }
}

export async function handleCreation(
  data,
  setErrors,
  showSnack,
  setLoading,
  setShowModal,
  setRegisteredLoad,
  isPreLoad
) {
  try {
    setLoading(true);
    if (!(await validate(data, setErrors))) {
      showSnack(
        'Verifique os campos obrigatórios!',
        { type: 'error' },
        'label-error-campos-obrigatorios'
      );
      return false;
    }
    if (
      !(await checkClientCreditLimit(
        data.client.id,
        +data?.total_taker_value,
        setErrors
      ))
    ) {
      showSnack(
        'Cliente sem limite de crédito! Não é possível cadastrar carga para tomadores sem limite de crédito!',
        { type: 'error' },
        'label-error-cliente-sem-credito'
      );
      return false;
    }

    let originList = {};
    let destinationList = {};
    let payload = {};

    if (isPreLoad) {
      originList = {
        address: data.origin?.address,
        number: data.origin?.number,
        complement: data.origin?.complement,
        zipCode: data.origin?.zip_code,
        lat: data.origin?.lat,
        lng: data.origin?.lng,
        cityId: data?.origin?.cityModel?.id || null,
        city: data?.origin?.cityModel?.name || null,
        province: data?.origin?.province,
        neighborhood: data.origin?.neighborhood,
        formattedAddress: data.origin?.formatted_address,
        startSchedule: moment(data?.origin.start_schedule, 'DD/MM/YYYY HH:mm'),
        scheduledTime: moment(data.origin?.date, 'DD/MM/YYYY HH:mm'),
        clientId: data?.origin?.client?.id || null,
        countrySlug:
          data?.origin?.country_slug || data?.origin?.country?.abbreviation,
      };
      destinationList = data.destinations.map(dest => ({
        address: dest?.address,
        number: dest?.number,
        complement: dest?.complement,
        zipCode: dest?.zip_code,
        province: dest?.province,
        lat: dest?.lat,
        lng: dest?.lng,
        scheduledTime: moment(dest?.date, 'DD/MM/YYYY HH:mm'),
        cityId: dest?.cityModel?.id,
        city: dest?.cityModel?.name,
        neighborhood: dest?.neighborhood,
        formattedAddress: dest?.formatted_address,
        countrySlug: dest?.country_slug || dest?.country?.abbreviation,
        action: dest?.type?.id,
        startSchedule: moment(dest?.start_schedule, 'DD/MM/YYYY HH:mm'),
        clientId: dest.client?.id || null,
      }));
      payload = {
        id: data?.preLoadId,
        negotiatorId: data.negotiator[0]?.id,
        personClientId: data.client.id,
        productId: data?.isPreloadWithGRO
          ? data?.preLoadPolicy?.selectedProduct.id
          : data.product.id,
        branchOfficeId: data.branchOffice?.id,
        costCenterId: data.costCenter?.id,
        takerValue: +data.taker_value,
        negotiationId: data?.negotiation?.id || null,
        dispositionId: data.selectedDisposition.id,
        contactInfo: `+${data.country.ddi} ${data.phone}`,
        currency: data.currency?.value || 'BRL',
        loadWeight: +convertNumberToDatabase(data.weight),
        dueDate: data.dueDate ? moment(data.dueDate, 'DD/MM/YYYY HH:mm') : null,
        searchDriver: data?.searchDriver ? 1 : 0,
        searchDriverRadius: data?.searchDriver
          ? data?.searchDriverRadius
            ? Number(data?.searchDriverRadius)
            : 500
          : 0,
        clientOrderNumber: data?.clientOrderNumber || null,
        externalInvoiceNumber: data?.externalInvoiceNumber || null,
        comments: data.observations,
        crawledLoad: data.tracked,
        tolledLoad: data?.isTollIncluded || null,
        tollValue: +data.toll_value,
        netWeight: +convertNumberToDatabase(data.net_weight),
        dischargeValue: +data.discharge_value,
        insuranceValue: +data.insurance || 0,
        mopp: data.mopp ? 1 : 0,
        gris: data.gris,
        adValorem: data.ad_valorem,
        advaloremIncluded: data.isAdValoremIncluded | null,
        ferry: +data?.ferry,
        icms: +data?.ICMS,
        chargeValue: +data.charge_value,
        palletNumber: data.pallets || null,
        isEscorted: data.isEscorted,
        pcpObservation: data?.pcp_observation,
        totalShipmentTransfer: +data?.total_shipment_transfer,
        totalInputs: +data?.total_inputs,
        totalShipmentDiscounts: +data?.total_shipment_discounts,
        icmsPercentage: data?.icms_percentage || null,
        grisPercentage: data?.gris_percentage || null,
        adValoremPercentage: data?.ad_valorem_percentage || null,
        trackerId: data.tracker ? data.tracker.id : null,
        withBait: data.withBait,
        baitNumber: data.baitNumber ? data.baitNumber : null,
        isTollPayerClient: data?.isTollPayerClient || 0,
        unloadIncluded: data?.isDischargeIncluded || null,
        chargeIncluded: data?.isChargeIncluded || null,
        fareCompany: +data?.fare_company,
        productTypeId: data.product.product_type_id,
        cargoValue: +data?.cargoValue,
        loadVolume: String(data.volume),
        insuranceIncluded: data.isInsuranceIncluded || null,
        typeValue: data?.freightType?.value,
        grisIncluded: data.isGrisIncluded || null,
        adValoremIncluded: data.isAdValoremIncluded | null,
        exchange: +data?.exchange || 0,
        loadCurrency: data?.load_currency?.value || 'BRL',
        routeId: data?.routes?.id || null,
        crt: data?.crt,
        temperature: data?.temperature || null,
        freightExchange: +data?.freight_exchange || 0,
        exchangeDay: +data?.exchange_day,
        totalTakerValue: +data?.total_taker_value,
        loadValueType: data?.load_value_type?.value,
        actionTollId: data?.action_toll?.id || null,
        actionDischargeId: data?.action_discharge?.id || null,
        actionChargeId: data?.action_charge?.id || null,
        actionGrissAdvaloremId: data?.action_griss_advalorem?.id || null,
        actionIcmsId: data?.action_icms?.id || null,
        actionFerryId: data?.action_ferry?.id || null,
        negotiatorIds: data.negotiator.map(item => item.id),
        extraFields:
          data?.extraFields?.length > 0
            ? data?.extraFields?.map(extra => {
                return {
                  label: extra?.label,
                  type: extra?.type ? 1 : 0,
                  value: Number(extra?.value),
                };
              })
            : null,
        vehicleTypeIds: data.selectedVehicles.map(type => type.id),
        vehicleImplementTypeIds: data.selectedImplements
          ? data.selectedImplements.map(implement => implement.id)
          : [],
        vehicleBodyTypeIds: data.selectedBodies.map(body => body.id),
        tagIds: data.tags?.map(tag => tag.id),
        automationIds: data.selectedAutomations.map(
          automation => automation.id
        ),
        riskManagerIds: data.riskManager
          ? data.riskManager.map(item => item.id)
          : [],
        loadOrigins: originList,
        loadDestinations: destinationList,
        policyId: data?.operationPreLoad?.data?.operation?.policy?.id,
        policyOperationId: data?.operationPreLoad?.data?.operation?.id,
      };
    } else {
      originList = {
        address: data.origin?.address,
        number: data.origin?.number,
        complement: data.origin?.complement,
        zip_code: data.origin?.zip_code,
        province: data?.origin?.province,
        lat: data.origin?.lat,
        lng: data.origin?.lng,
        scheduled_time: moment(data.origin?.date, 'DD/MM/YYYY HH:mm'),
        status: 'current',
        city_id: data?.origin?.cityModel?.id || null,
        city: data?.origin?.cityModel?.name,
        neighborhood: data.origin?.neighborhood,
        country_slug:
          data?.origin?.country_slug || data?.origin?.country?.abbreviation,
        formatted_address: data.origin?.formatted_address,
        start_schedule: moment(data?.origin.start_schedule, 'DD/MM/YYYY HH:mm'),
        client_id: data?.origin?.client?.id || null,
      };

      destinationList = data.destinations.map(dest => ({
        address: dest?.address,
        number: dest?.number,
        complement: dest?.complement,
        zip_code: dest?.zip_code,
        province: dest?.province,
        lat: dest?.lat,
        lng: dest?.lng,
        scheduled_time: moment(dest?.date, 'DD/MM/YYYY HH:mm'),
        status: 'current',
        city_id: dest?.cityModel?.id,
        city: dest?.cityModel?.name,
        neighborhood: dest?.neighborhood,
        formatted_address: dest?.formatted_address,
        country_slug: dest?.country_slug || dest?.country?.abbreviation,
        action: dest?.type?.id,
        start_schedule: moment(dest?.start_schedule, 'DD/MM/YYYY HH:mm'),
        client_id: dest.client?.id || null,
      }));

      payload = {
        load_weight: +convertNumberToDatabase(data.weight),
        net_weight: +convertNumberToDatabase(data.net_weight),
        monitoring: 1,
        tracking: 1,
        crawled_load: data.tracked,
        tracker_id: data.tracker ? data.tracker.id : null,
        is_escorted: data.isEscorted,
        with_bait: data.withBait,
        bait_number: data.baitNumber ? data.baitNumber : null,
        tolled_load: data?.isTollIncluded || null,
        is_toll_payer_client: data?.isTollPayerClient || 0,
        unload_included: data?.isDischargeIncluded || null,
        charge_included: data?.isChargeIncluded || null,
        contact_info: `+${data.country.ddi} ${data.phone}`,
        negotiator_id: data.negotiator.map(item => item.id),
        fare_company: +data?.fare_company,
        comments: data.observations,
        person_client_id: data.client.id,
        product_id: data.product.id,
        product_type: data.product.product_type_id,
        branch_office_id: data.branchOffice?.id,
        cargo_value: +data?.cargoValue,
        load_volume: data.volume,
        pallet_number: data.pallets || null,
        vehicleTypes: data.selectedVehicles.map(type => type.id),
        vehicle_implement: data.selectedImplements
          ? data.selectedImplements.map(implement => implement.id)
          : [],
        originList,
        destinationList,
        bodyTypes: data.selectedBodies.map(body => body.id),
        selectedTagList: data.tags?.map(tag => ({ value: tag.id })),
        due_date: data.dueDate
          ? moment(data.dueDate, 'DD/MM/YYYY HH:mm')
          : null,
        search_driver: data?.searchDriver ? 1 : 0,
        search_driver_radius: data?.searchDriver
          ? data?.searchDriverRadius
            ? Number(data?.searchDriverRadius)
            : 500
          : 0,
        orderedStepsDue: [],
        automations: data.selectedAutomations.map(automation => automation.id),
        toll_value: +data.toll_value,
        discharge_value: +data.discharge_value,
        charge_value: +data.charge_value,
        insurance_included: data.isInsuranceIncluded || null,
        insurance_value: +data.insurance || 0,
        mopp: data.mopp,
        loadClearances: data.riskManager
          ? data.riskManager.map(item => item.id)
          : [],
        pricingOrders: data?.pricingOrders
          ?.filter(order => order.price && order.due_date)
          ?.map(order => ({
            company_order_id: order.company_order_id,
            due_date: moment(order.due_date, 'DD/MM/YYYY HH:mm'),
            price: +order.price,
          })),
        type_value: data?.freightType?.value,
        taker_value: +data.taker_value,
        load_disposition_id: data.selectedDisposition.id,
        gris: data.gris,
        ad_valorem: data.ad_valorem,
        gris_included: data.isGrisIncluded || null,
        ad_valorem_included: data.isAdValoremIncluded | null,
        extra_fields: data?.extraFields ?? [],
        cost_center_id: data.costCenter?.id,
        exchange: +data?.exchange || 0,
        load_currency: data?.load_currency?.value || 'BRL',
        route_id: data?.routes?.id || null,
        negotiation_id: data?.negotiation?.id || null,
        crt: data?.crt,
        client_order_number: data?.clientOrderNumber || null,
        temperature: data?.maxTemperature || null,
        temperature_min: data?.minTemperature || null,
        external_invoice_number: data?.externalInvoiceNumber || null,
        currency: data.currency?.value || 'BRL',
        freight_exchange: +data?.freight_exchange || 0,
        exchange_day: +data?.exchange_day,
        total_taker_value: +data?.total_taker_value,
        total_inputs: +data?.total_inputs,
        total_shipment_transfer: +data?.total_shipment_transfer,
        total_shipment_discounts: +data?.total_shipment_discounts,
        load_value_type: data?.load_value_type?.value,
        negotiation_type: data?.negotiation_type?.value,
        ferry: +data?.ferry,
        icms: +data?.ICMS,
        pcp_observation: data?.pcp_observation,
        action_toll: data?.action_toll?.id || null,
        action_discharge: data?.action_discharge?.id || null,
        action_griss_advalorem: data?.action_griss_advalorem?.id || null,
        action_charge: data?.action_charge?.id || null,
        action_icms: data?.action_icms?.id || null,
        action_ferry: data?.action_ferry?.id || null,
        gris_percentage: data?.gris_percentage || null,
        ad_valorem_percentage: data?.ad_valorem_percentage || null,
        icms_percentage: data?.icms_percentage || null,
      };
    }

    let load;
    for (let i = 0; i < data.loadCreationNumber; i++) {
      let response;
      if (isPreLoad) {
        response = await cooperplaceApi.put(
          `loads/${data?.preLoadId}/complete`,
          payload
        );
      } else {
        response = await api.post('v3/loads', payload);
      }
      load = response.data.id;

      let inputsExtraFields = [...data?.inputsExtraFields];
      let transferExtraFields = [...data?.transferExtraFields];
      let discountsExtraFields = [...data?.discountsExtraFields];

      transferExtraFields = transferExtraFields.map(item => {
        return {
          ...item,
          load_id: load,
        };
      });

      const issueRateAsExtraField = {
        label: 'Taxa de emissão',
        manual: true,
        value: Number(data?.issueRate),
        showToDriver: false,
      };
      discountsExtraFields.push(issueRateAsExtraField);
      discountsExtraFields = discountsExtraFields.map(item => {
        return {
          ...item,
          load_id: load,
        };
      });
      const hasCoopercargaInsurance = !!data?.coopercarga_insurance;
      if (hasCoopercargaInsurance) {
        const newInputsExtraField = {
          label: 'Seguro Coopercarga',
          manual: true,
          value: Number(data.coopercarga_insurance),
          showToDriver: false,
        };
        inputsExtraFields.push(newInputsExtraField);
      }
      inputsExtraFields = inputsExtraFields?.map(item => {
        return { ...item, load_id: load };
      });
      try {
        await cooperplaceApi.put('/carg-inputs', { inputsExtraFields });
        await cooperplaceApi.put('carg-shipment-transfers/', {
          transferExtraFields,
        });
        await cooperplaceApi.put('carg-shipment-discounts/', {
          discountsExtraFields,
        });
      } catch (err) {
        console.error(err);
      }
    }

    setShowModal(true);
    setRegisteredLoad(load);

    try {
      await apiNotification.post(`match/load/${load}`);
    } catch (ex) {
      //
    }

    try {
      await cargonApi.post('load/send', { load_id: load });
    } catch (ex) {
      //
    }

    return true;
  } catch (err) {
    throw err;
  } finally {
    setLoading(false);
  }
}
