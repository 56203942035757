import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import cooperplaceApi from 'services/apis/cooperplace';
import Select from 'v3/components/Select';
import Text from 'v3/components/Text';
import Card from 'v3/components/Card';
import { useSnackbar } from 'v3/components/Snackbar';
import { useModal, usePermission } from 'hooks';
import Tooltip from 'v3/components/Tooltip';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import VerifyVehiclesModal from 'v3/components/VerifyVehiclesModal';
import {
  trackedVehicleRequiredFiels,
  validAnttRegex,
  vehicleRequiredFields,
  wagonRequiredFields,
} from 'v3/utils/constants';
import { format } from 'date-fns';
import moment from 'moment';
import RegisterVehicleModal from '../Modals/RegisterVehicle';
import { TravelContext } from '../controller';
import { UpdateVehicleData } from '../Modals/UpdateVehicle';
import { UpdateWagonData } from '../Modals/UpdateWagon';
import { RegisterWagon } from '../Modals/RegisterWagon';

const [, useTravel] = TravelContext;

export default function VehicleData() {
  const { setItensToFetchMargin, setIsFetchingBenner, isFetchingBenner } =
    useContext(BlockedTravelContext);
  const travel = useTravel();
  const hasPermissionToRegisterVehice = usePermission('CADASTRAR_VEICULOS');
  const hasPermissionToRegisterWagon = usePermission('CADASTRAR_IMPLEMENTO');
  const registerVehicle = useModal();
  const registerWagon = useModal();
  const updateVehicleData = useModal();
  const updateWagonData = useModal();
  const snackbar = useSnackbar();
  const [vehicle, setVehicle] = useState(null);
  const [wagon, setWagon] = useState(null);
  const [currentWagonRegister, setCurrentWagonRegister] = useState('');
  const hasPermissionToEditVehicle = usePermission('ALTERAR_VEICULOS');
  const hasPermissionToEditWagon = usePermission('EDITAR_IMPLEMENTO');
  const [trackerRequired, setTrackerRequired] = useState(false);

  async function fetchVehicle(search) {
    try {
      const response = await api.get('vehicle/license_plate', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function fetchWagons(search) {
    try {
      const response = await api.get('plate/wagons', {
        params: { search },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function getUpdatedVehicle(vehicleId) {
    const getVehicleUrl = `/vehicles/${vehicleId}`;
    const getVehicle = await api.get(getVehicleUrl);
    return getVehicle.data;
  }

  async function getUpdatedWagon(wagonId) {
    const getVehicleUrl = `/wagons/${wagonId}`;
    const getVehicle = await cooperplaceApi.get(getVehicleUrl);
    return getVehicle.data;
  }

  const importVehicleFromBenner = async plate => {
    const url = `vehicles/importFromBenner?plate=${plate}`;
    const { data } = await cooperplaceApi.get(url);
    const updatedVehicle = await getUpdatedVehicle(data.id);

    setItensToFetchMargin(prevState => ({
      ...prevState,
      mainVehicle: updatedVehicle,
    }));

    snackbar.show(<Text>{data?.message}</Text>, {
      type: 'success',
    });
    return updatedVehicle;
  };

  const importWagonFromBenner = async plate => {
    const url = `wagons/importFromBenner?plate=${plate}`;
    const { data } = await cooperplaceApi.put(url);
    return data?.id;
  };

  function checkVehicleData(data) {
    const validation = vehicleRequiredFields.filter(field => !data[field]);

    const anttNumberValidation = validAnttRegex.test(data?.antt);

    let trackValidation = null;
    const shouldValidatedTracker = data?.tracked;
    if (shouldValidatedTracker) {
      trackValidation = trackedVehicleRequiredFiels.filter(
        field => !data[field]
      );

      return (
        validation?.length > 0 ||
        !anttNumberValidation ||
        trackValidation?.length > 0
      );
    }
    return validation?.length > 0 || !anttNumberValidation;
  }

  function checkVehicleTrackedData(data) {
    const requiredFields = ['tracker_id', 'tracker_type_id', 'tracker_code', 'tracked']
    return requiredFields.find(field => !data[field])
  }

  function checkWagonData(data) {
    const anttNumberValidation = validAnttRegex.test(data?.antt);
    if (data?.vehicle_implement_type_name === 'Dolly') {
      const validation = wagonRequiredFields
        .filter(field => field !== 'vehicle_body_type_id')
        .filter(field => !data[field]);
      return validation?.length > 0 || !anttNumberValidation;
    }
    const validation = wagonRequiredFields.filter(field => !data[field]);

    return validation?.length > 0 || !anttNumberValidation;
  }

  function handleUpdateVehicle(data) {
    travel.setData(old => ({
      ...old,
      vehicle: { ...old.vehicle, main: data },
    }));
  }

  function handleUpdateWagon() {
    travel.setData(old => ({
      ...old,
      vehicle: { ...old.vehicle, [wagon.key]: wagon?.wagonData },
    }));
  }

  const handleChangeVehicle = async value => {
    if (value) {
      let updatedVehicle = value;
      try {
        setIsFetchingBenner(true);
        updatedVehicle = await importVehicleFromBenner(value.plate);
      } catch (error) {
        snackbar.show(<Text>Veículo não encontrado no Benner!</Text>, {
          type: 'error',
        });
      } finally {
        setIsFetchingBenner(false);
      }
      setVehicle({
        id: updatedVehicle?.id,
        plate: updatedVehicle?.plate,
        year_manufacture: updatedVehicle?.year_manufacture,
        year_model: updatedVehicle?.year_model,
        chassi: updatedVehicle?.chassi,
        vehicleType: updatedVehicle?.vehicleType,
        vehicle_type_id: updatedVehicle?.vehicle_type_id,
        vehicle_implement_type: updatedVehicle?.vehicle_implement_type,
        implementType: updatedVehicle?.implementType,
        vehicleBodyType: updatedVehicle?.vehicleBodyType,
        vehicle_body_type_id: updatedVehicle?.vehicle_body_type_id,
        renavam: updatedVehicle?.renavam,
        antt_type: updatedVehicle?.antt_type,
        anttType: updatedVehicle?.anttType,
        antt: updatedVehicle?.antt,
        antt_adherence: updatedVehicle?.antt_adherence
          ? format(
              new Date(moment(updatedVehicle?.antt_adherence).add(1, 'day')),
              'yyyy-MM-dd'
            )
          : null,
        tracked: updatedVehicle?.tracked,
        tracker_code: updatedVehicle?.tracker_code,
        tracker: updatedVehicle?.tracker,
        tracker_type: updatedVehicle?.trackerType,
        city: updatedVehicle?.city
          ? {
              id: updatedVehicle?.city?.id,
              name: updatedVehicle?.city?.name,
              uf: updatedVehicle?.city?.province?.uf,
            }
          : null,
        owner_id: updatedVehicle?.owner_id,
        beneficiary_id: updatedVehicle?.beneficiary_id,
        beneficiary: updatedVehicle?.beneficiary,
        ownerData: updatedVehicle?.ownerData,
        tags: updatedVehicle?.tags || [],
      });
      const needUpdateVehicle = checkVehicleData(updatedVehicle)
      const trackedFieldRequired = travel.data?.crawledLoad && checkVehicleTrackedData(updatedVehicle)
      setTrackerRequired(trackedFieldRequired)
      if (needUpdateVehicle || trackedFieldRequired) {
        let message = ''
        if (hasPermissionToEditVehicle) {
          if (needUpdateVehicle) {
            message = 'Cadastro de veículo incompleto'
          }
          
          if (trackedFieldRequired) {
            message = 'Carga exige informações de rastreamento'
          }
        } else {
          message = 'Solicite atualização do cadastro do veículo para utilizá-lo na viagem'
        }

        snackbar.show(<Text>{message}</Text>, {
          type: 'warning',
        });

        if (hasPermissionToEditVehicle) {
          return updateVehicleData.open();
        }
        travel.setData(old => ({
          ...old,
          vehicle: { ...old.vehicle, main: null },
        }));
        return;
      }
      travel.setData(old => ({
        ...old,
        vehicle: { ...old.vehicle, main: updatedVehicle },
      }));
    } else
      travel.setData(old => ({
        ...old,
        vehicle: { ...old.vehicle, main: value },
      }));
  };

  function wagonDataMapper(data) {
    return {
      id: data?.id,
      plate: data?.plate,
      year_manufacture: data?.year_manufacture || data?.yearManufacture,
      year_model: data?.year_model || data?.yearModel,
      chassi: data?.chassi,
      vehicleType: data?.vehicleType,
      vehicle_type_id: data?.vehicle_type_id || data?.vehicleTypeId,
      bodyType: data?.bodyType,
      vehicle_body_type_id:
        data?.vehicle_body_type_id || data?.vehicleBodyTypeId,
      vehicle_implement_type_id:
        data?.vehicle_implement_type_id || data?.vehicleImplementTypeId,
      implementType: data?.implementType,
      renavam: data?.renavam,
      antt_type: data?.antt_type || data?.anttType,
      anttType: data?.anttTypes || data?.anttType,
      antt: data?.antt,
      antt_adherence:
        data?.antt_adherence || data?.anttAdherence
          ? format(
              new Date(
                moment(data?.antt_adherence || data?.anttAdherence).add(
                  1,
                  'day'
                )
              ),
              'yyyy-MM-dd'
            )
          : null,
      city: data?.city
        ? {
            id: data?.city?.id,
            name: data?.city?.name,
            uf: data?.city?.province?.uf,
          }
        : null,
      id_owner: data?.id_owner || data?.idOwner,
      owner: data?.owner || data?.ownerWagon,
      beneficiary_id: data?.beneficiary_id || data?.beneficiaryId,
      beneficiary: data?.beneficiary || data?.beneficiaryWagon,
    };
  }

  function wagonDataToValidate(data) {
    return {
      plate: data?.plate,
      chassi: data?.chassi,
      renavam: data?.renavam,
      year_model: data?.year_model || data?.yearModel,
      year_manufacture: data?.year_manufacture || data?.yearManufacture,
      plate_city_id: data?.city?.id,
      vehicle_type_id: data?.vehicle_type_id || data?.vehicleTypeId,
      vehicle_body_type_id:
        data?.vehicle_body_type_id || data?.vehicleBodyTypeId,
      vehicle_implement_type_id:
        data?.vehicle_implement_type_id || data?.vehicleImplementTypeId,
      vehicle_implement_type_name: data?.implementType?.name,
      antt_type: data?.antt_type || data?.anttType,
      id_owner: data?.id_owner || data?.idOwner,
      beneficiary_id: data?.beneficiary_id || data?.beneficiaryId,
      antt_adherence: data?.antt_adherence || data?.anttAdherence,
      antt: data?.antt,
    };
  }

  const handleChangeWagon = async (value, wagon) => {
    if (value) {
      let updatedWagon = value;
      try {
        setIsFetchingBenner(true);
        const plateId = await importWagonFromBenner(value?.plate);
        updatedWagon = await getUpdatedWagon(plateId);
      } catch (error) {
        snackbar.show(<Text>Veículo não encontrado no Benner!</Text>, {
          type: 'error',
        });
      } finally {
        setIsFetchingBenner(false);
      }
      const wagonMapped = wagonDataMapper(updatedWagon);
      setWagon({
        key: wagon,
        wagonData: wagonMapped,
      });
      const wagonFieldsToValidate = wagonDataToValidate(updatedWagon);

      if (checkWagonData(wagonFieldsToValidate)) {
        const message = hasPermissionToEditWagon
          ? 'Cadastro de implemento incompleto'
          : 'Solicite atualização do cadastro do implemento para utilizá-lo na viagem';
        snackbar.show(<Text>{message}</Text>, {
          type: 'warning',
        });
        if (hasPermissionToEditWagon) {
          return updateWagonData.open();
        }
        travel.setData(old => ({
          ...old,
          vehicle: {
            ...old.vehicle,
            [wagon]: null,
          },
        }));
        return;
      }
      travel.setData(old => ({
        ...old,
        vehicle: {
          ...old.vehicle,
          [wagon]: updatedWagon,
        },
      }));
    } else {
      travel.setData(old => ({
        ...old,
        vehicle: {
          ...old.vehicle,
          [wagon]: value,
        },
      }));
    }
  };

  function sendResponse({ wagonKey, response }) {
    travel.setData(old => ({
      ...old,
      vehicle: {
        ...old.vehicle,
        [wagonKey]: response?.data,
      },
    }));
  }

  function handleRegisterWagon(key) {
    setCurrentWagonRegister(key);
    return registerWagon.open();
  }

  return (
    <>
      <RegisterVehicleModal
        isOpen={registerVehicle.isOpen}
        onClose={registerVehicle.close}
      />
      <RegisterWagon
        isOpen={registerWagon.isOpen}
        onClose={() => registerWagon.close()}
        wagonKey={currentWagonRegister}
        sendResponse={sendResponse}
      />
      <UpdateVehicleData
        trackerRequired={trackerRequired}
        isOpen={updateVehicleData.isOpen}
        onClose={() => updateVehicleData.close()}
        onSubmit={handleUpdateVehicle}
        vehicle={vehicle}
        setVehicle={setVehicle}
      />
      <UpdateWagonData
        isOpen={updateWagonData.isOpen}
        onClose={() => updateWagonData.close()}
        onSubmit={handleUpdateWagon}
        wagon={wagon}
        setWagon={setWagon}
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Veículo
          </Text>
        }
      >
        <Row className="form">
          <Col md={6} xs={12}>
            <Select.Async
              onSearch={fetchVehicle}
              placeholder=""
              label="Placa principal *"
              value={travel.data.vehicle?.main}
              onChange={handleChangeVehicle}
              getOptionLabel={option => option.plate}
              getOptionValue={option => option.id}
              error={travel.errors.vehicleId}
              NoOptionsComponent={() => (
                <Text
                  color="gray"
                  type="regular"
                  onClick={() => {
                    if (!hasPermissionToRegisterVehice) return;
                    registerVehicle.open();
                  }}
                  clickable={hasPermissionToRegisterVehice}
                  id="button-veiculo-nenhum-encontrado-cadastrar"
                >
                  {hasPermissionToRegisterVehice
                    ? 'Nenhuma placa encontrada, clique aqui para cadastrar'
                    : 'Nenhuma placa encontrada'}
                </Text>
              )}
              isDisabled={isFetchingBenner}
              id="select-veiculo-placa-principal"
            />
            {hasPermissionToRegisterVehice && (
              <Tooltip
                content={
                  <Text type="label">Cadastro simplificado de veículo</Text>
                }
              >
                <Text
                  type="label"
                  weight="500"
                  color="dark_blue"
                  clickable
                  onClick={() => registerVehicle.open()}
                  id="button-veiculo-cadastrar"
                >
                  Veículo novo? Clique aqui para cadastrar
                </Text>
              </Tooltip>
            )}
          </Col>
          <Col md={6} xs={12}>
            <Select.Async
              onSearch={fetchWagons}
              label="Placa implemento 1"
              placeholder=""
              value={
                travel.data.vehicle?.implement?.plate
                  ? travel.data.vehicle?.implement
                  : null
              }
              onChange={value => handleChangeWagon(value, 'implement')}
              getOptionLabel={option => {
                let label = option.plate;
                if (option.vehicle) {
                  label += ` - Tração ${option.vehicle.plate}`;
                }

                return label;
              }}
              getOptionValue={option => option.id}
              isDisabled={isFetchingBenner}
              id="select-veiculo-placa-implemento-um"
            />
            {hasPermissionToRegisterWagon && (
              <Tooltip
                content={
                  <Text type="label">Cadastro simplificado de implemento</Text>
                }
              >
                <Text
                  type="label"
                  weight="500"
                  color="dark_blue"
                  clickable
                  onClick={() => {
                    handleRegisterWagon('implement');
                  }}
                  id="button-implemento-um-cadastrar"
                >
                  Implemento novo? Clique aqui para cadastrar
                </Text>
              </Tooltip>
            )}
          </Col>

          <Col md={6} xs={12}>
            <Select.Async
              onSearch={fetchWagons}
              placeholder=""
              label="Placa implemento 2"
              value={
                travel.data.vehicle?.implement2?.plate
                  ? travel.data.vehicle?.implement2
                  : null
              }
              onChange={value => handleChangeWagon(value, 'implement2')}
              getOptionLabel={option => option.plate}
              getOptionValue={option => option.id}
              isDisabled={isFetchingBenner}
            />
            {hasPermissionToRegisterWagon && (
              <Tooltip
                content={
                  <Text type="label">Cadastro simplificado de implemento</Text>
                }
              >
                <Text
                  type="label"
                  weight="500"
                  color="dark_blue"
                  clickable
                  onClick={() => {
                    handleRegisterWagon('implement2');
                  }}
                >
                  Implemento novo? Clique aqui para cadastrar
                </Text>
              </Tooltip>
            )}
          </Col>

          <Col md={6} xs={12}>
            <Select.Async
              onSearch={fetchWagons}
              placeholder=""
              label="Placa implemento 3"
              value={
                travel.data.vehicle?.implement3?.plate
                  ? travel.data.vehicle?.implement3
                  : null
              }
              onChange={value => handleChangeWagon(value, 'implement3')}
              getOptionLabel={option => option.plate}
              getOptionValue={option => option.id}
              isDisabled={isFetchingBenner}
            />
            {hasPermissionToRegisterWagon && (
              <Tooltip
                content={
                  <Text type="label">Cadastro simplificado de implemento</Text>
                }
              >
                <Text
                  type="label"
                  weight="500"
                  color="dark_blue"
                  clickable
                  onClick={() => {
                    handleRegisterWagon('implement3');
                  }}
                >
                  Implemento novo? Clique aqui para cadastrar
                </Text>
              </Tooltip>
            )}
          </Col>
        </Row>
      </Card>
      <VerifyVehiclesModal vehicles={travel.data.vehicle} />
    </>
  );
}
